import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Página de soporte de la App: "Ejp Club"
        </h1>
        <p>
        Para cualquier incidencia escriban un correo a blopadev@gmail.com
        </p>
      </header>
    </div>
  );
}

export default App;
